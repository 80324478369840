/* { box-sizing: border-box; } */

body {
  margin: 0;
  padding: 0;
  font-family:
    /* Chrome < 56 for OS X (San Francisco) */
    BlinkMacSystemFont,

    /* Safari for OS X and iOS (San Francisco) */
    -apple-system,

    /* Windows */
    "Segoe UI",

    /* Android */
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",

    /* Basic web fallback */
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif,

    /* Emoji fonts */
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family:
    /* Chrome < 56 for OS X (San Francisco) */
    BlinkMacSystemFont,

    /* Safari for OS X and iOS (San Francisco) */
    -apple-system,

    /* Windows */
    "Segoe UI",

    /* Android */
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",

    /* Basic web fallback */
    "Helvetica Neue",
    Helvetica,
    Arial,
    sans-serif,

    /* Emoji fonts */
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
  font-weight: 400;
  color: #3f4497;
}

pre {
  font-family: 'News Cycle', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 16px;
}

p.intro {
  font-size: 18px;
}

.text-thin {
  font-weight: 100;
}

.text-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.btn-rounded {
  padding: 10px 40px;
  border-radius: 100px;
}
